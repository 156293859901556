body .app-wrap {
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: auto auto auto auto auto auto auto;
    grid-template-areas:  "header"
                          "highlighted"
                          "help"
                          "section-top"
                          "content"
                          "section-bottom"
                          "footer";
    margin: 60px 0;
    @media (max-width: 760px) {
      margin: 20px 0;
    }
  }

  .landing-max-width {
    display: grid;
    grid-template-areas:  ". container .";
  }

  // autoprefixer works when template rows and columns are declared in the same block, thus we must repeat the rows declaration
  .landing-max-width {
    grid-template-rows: auto;
    grid-template-columns: minmax($mobileMargins, 1fr) minmax(auto, $landingMaxWidth) minmax($mobileMargins, 1fr);
  }

  // generic nested container for wrappers above
  .container {
    grid-area: container;
  }

  #header {
    grid-area: header;
  }

  #help {
    grid-area: help;
  }

  #highlighted {
    grid-area: highlighted;
    position: relative; // for the children
  }

  #section-top {
    grid-area: section-top;
  }

  #content {
    grid-area: content;
    // position: absolute; // support vue transitions
  }

  #section-bottom {
    grid-area: section-bottom;
  }

  #footer {
    grid-area: footer;
  }

  // ie support
  .hero-max-width,
  .landing-max-width,
  .editorial-max-width,
  .component-max-width {
    -ms-grid-columns: 1fr 960px 1fr;
  }
